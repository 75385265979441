export type SmartContract = {
  id: string;
  method: string;
  wallet: string;
  status: SmartContractStatus;
};

export type CreateSmartContractParams = {
  method: string;
  wallet: string;
};

export enum SmartContractStatus {
  Requested = 'requested',
  Blocked = 'blocked',
  Approved = 'approved',
}
