import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

export const ErrorReqHandler = () => {
  const toast = useToast();

  useEffect(() => {
    const showToast = () =>
      toast({
        title: 'Server error',
        status: 'error',
        isClosable: true,
      });

    document.addEventListener('unhandled-error', showToast);

    return () => document.removeEventListener('unhandled-error', showToast);
  }, [toast]);

  return null;
};
