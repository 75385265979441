/**
 * env variables initialization module
 * @remark If the value of at least one variable is not found,
 * The application will immediately throw an error when the module is initialized
 * @module
 */

/**
 * Getting an env variable
 * @throwable
 */
const getEnvVar = (key: string) => {
  if (process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`);
  }
  return process.env[key] || '';
};

/** API entrypoint */
export const API_URL = getEnvVar('REACT_APP_API_URL');

/** Program launch mode */
export const NODE_ENV = getEnvVar('NODE_ENV');
/** Development mode */
export const isDevEnv = NODE_ENV === 'development';
/** Production mode */
export const isProdEnv = NODE_ENV === 'production';

/** Auth user data call interval (in seconds) */
export const AUTH_USER_DATA_CALL_INTERVAL_SEC = parseInt(
  getEnvVar('REACT_APP_AUTH_USER_DATA_CALL_INTERVAL_SEC'),
  10,
);

/** Mixpanel */
export const MIXPANEL_KEY = getEnvVar('REACT_APP_MIXPANEL_KEY');
export const MIXPANEL_DEBUG = getEnvVar('REACT_APP_MIXPANEL_DEBUG') === 'true';
