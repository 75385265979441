import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const SignInPage = lazy(() => import('./sign-in'));
const SignUpPage = lazy(() => import('./sign-up'));
const SettingsPage = lazy(() => import('./settings'));
const DashboardPage = lazy(() => import('./dashboard'));
const ApiKeysPage = lazy(() => import('./api-keys'));
const SmartContractsPage = lazy(() => import('./smart-contracts'));
const VerificationEmailPage = lazy(() => import('./verification-email'));
const PasswordForgotPage = lazy(() => import('./password-forgot'));
const PasswordResetPage = lazy(() => import('./password-reset'));
const ReferralsPage = lazy(() => import('./referrals'));

type RoutingProps = {
  isAuth: boolean;
};

export const Routing = (props: RoutingProps) => {
  const { isAuth } = props;

  return (
    <Routes>
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/password-forgot" element={<PasswordForgotPage />} />
      <Route path="/password-reset" element={<PasswordResetPage />} />
      <Route
        path="/merchants/verification/email/:id/:hash"
        element={<VerificationEmailPage />}
      />
      {isAuth && (
        <>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/api-keys" element={<ApiKeysPage />} />
          <Route path="/smart-contracts" element={<SmartContractsPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/referrals" element={<ReferralsPage />} />
        </>
      )}
      <Route path="*" element={<Navigate to={isAuth ? '/' : '/sign-in'} />} />
    </Routes>
  );
};
