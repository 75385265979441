import mixpanel from 'mixpanel-browser';

type InitMixpanelParams = {
  token: string;
  debug?: boolean;
};
export const initMixpanel = (params: InitMixpanelParams) => {
  const { token, debug } = params;

  if (!token) {
    return;
  }

  mixpanel.init(token, { debug: debug ?? false, ip: true });
};
