import axios from 'axios';
import { API_URL } from 'shared/config';
import { sanctumCsrfMiddleware, sanctumCsrfMismatchMiddleware } from './csrf';

// axios instance with CSRF
export const apiInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
});

apiInstance.interceptors.request.use(sanctumCsrfMiddleware());
apiInstance.interceptors.response.use(
  (r) => r,
  sanctumCsrfMismatchMiddleware(apiInstance),
);
