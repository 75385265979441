import { makeAutoObservable } from 'mobx';

type ReqType<P, R> = (p: P) => Promise<R>;

type FetchWrapper<P, R> = (p: ReqType<P, R>) => ReqType<P, R>;

export class ApiRequestStore<
  ReqParams = void,
  ReqRes = void,
  ReqErr = unknown,
> {
  private readonly _req: ReqType<ReqParams, ReqRes>;

  private readonly _fetchWrapper: FetchWrapper<ReqParams, ReqRes> | null;

  private _pending = false;

  private _isLoaded = false;

  private _data: ReqRes | null = null;

  private _error: ReqErr | null = null;

  constructor(
    req: ReqType<ReqParams, ReqRes>,
    fetchWrapper: FetchWrapper<ReqParams, ReqRes> | null = null,
  ) {
    this._req = req;
    this._fetchWrapper = fetchWrapper;

    makeAutoObservable(this);
  }

  get isLoaded() {
    return this._isLoaded;
  }

  get pending() {
    return this._pending;
  }

  get data() {
    return this._data;
  }

  get error() {
    return this._error;
  }

  async fetch(params: ReqParams): Promise<ReqRes> {
    if (this._fetchWrapper) {
      return this._fetchWrapper((p) => this.fetchBody(p))(params);
    }

    return this.fetchBody(params);
  }

  reset() {
    this._data = null;
    this._error = null;
    this._pending = false;
    this._isLoaded = false;
  }

  private async fetchBody(params: ReqParams): Promise<ReqRes> {
    try {
      this.run();
      const data = await this._req(params);
      this.complete(data);
      return data;
    } catch (error) {
      this.fail(error as ReqErr);
      throw error;
    }
  }

  private run() {
    this._pending = true;
    this._data = null;
    this._error = null;
  }

  private complete(data: ReqRes) {
    this._isLoaded = true;
    this._data = data;
    this._pending = false;
  }

  private fail(error: ReqErr) {
    this._error = error;
    this._pending = false;
  }
}
