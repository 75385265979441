import { makeAutoObservable, runInAction } from 'mobx';
import { authApi, AuthUser } from 'shared/api';
import { ApiRequestStore } from 'shared/lib/mobx-extensions';

/**
 * Class for managing an authorized user
 * @author Andrey Kornienko
 */
class AuthUserStore {
  private _user: AuthUser | null = null;

  readonly request = new ApiRequestStore(authApi.getCurrentUser);

  constructor() {
    makeAutoObservable(this);
  }

  get user(): AuthUser | null {
    return this._user;
  }

  get isAuth(): boolean {
    return !!this._user;
  }

  setUser = (val: AuthUser | null) => {
    this._user = val;
  };

  load = async () => {
    const data = (await this.request.fetch()).data.result;
    runInAction(() => {
      this.setUser(data);
    });
  };
}

export const store = new AuthUserStore();
