import { AxiosResponse } from 'axios';
import { ApiResponse, apiInstance } from 'shared/lib/api';
import { CreateSmartContractParams, SmartContract } from './types';

export const getSmartContracts = async (): Promise<
  AxiosResponse<ApiResponse<SmartContract[]>>
> => {
  return apiInstance.get<ApiResponse<SmartContract[]>>(
    '/dashboard-api/v1/current/smart-contract-call/whitelist',
  );
};

export const createSmartContract = async (
  params: CreateSmartContractParams,
): Promise<AxiosResponse<ApiResponse<SmartContract>>> => {
  const res = await apiInstance.post<ApiResponse<SmartContract>>(
    '/dashboard-api/v1/current/smart-contract-call/whitelist',
    params,
  );

  return res;
};

export const deleteSmartContract = async (id: string): Promise<void> => {
  await apiInstance.delete(
    `/dashboard-api/v1/current/smart-contract-call/whitelist/${id}`,
  );
};
