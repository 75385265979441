import { Center, Spinner, VStack } from '@chakra-ui/react';

export const DataInitialLoader: React.FC = () => {
  return (
    <Center mt="250px">
      <VStack>
        <Spinner thickness="4px" speed="0.8s" color="lightblue" size="xl" />
      </VStack>
    </Center>
  );
};
