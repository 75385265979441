import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from 'shared/config';
import { singleInFlight } from 'shared/lib/promise-helpers';
import { isErrorCode } from './errors';
import { ApiError } from './types';

const csrfInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const updateCsrfToken = singleInFlight(() =>
  csrfInstance.get('/sanctum/csrf-cookie'),
);

interface AxiosConfigWithRetry extends AxiosRequestConfig {
  csrfRetry: boolean;
}

// Make sure CSRF token is available in cookies
export function sanctumCsrfMiddleware() {
  return async (request: AxiosRequestConfig) => {
    // ignore csrf update for CSRF request to avoid recursion
    if (request.url?.includes('/sanctum/csrf-cookie')) {
      return request;
    }

    // only update XSRF-TOKEN for first time
    if (!Cookies.get('XSRF-TOKEN')) {
      await updateCsrfToken();
    }

    return request;
  };
}

export function sanctumCsrfMismatchMiddleware(apiClient: AxiosInstance) {
  // update CSRF token on error & retry original request
  return async (error: AxiosError) => {
    if (error.response) {
      const response = error.response as AxiosResponse<ApiError>;
      const config = error.config as AxiosConfigWithRetry;
      if (
        response.status === 419 &&
        response.data &&
        response.data.error &&
        isErrorCode('csrf_token_mismatch', response.data) &&
        !config.csrfRetry
      ) {
        const newConfig = { ...config };
        newConfig.csrfRetry = true;
        await updateCsrfToken();
        return apiClient.request(newConfig);
      }
    }

    return Promise.reject(error);
  };
}
