import { Routing } from 'pages';
import './styles/index.scss';
import '@fontsource/open-sans';
import '@fontsource/inter';
import dayjs from 'dayjs';
/** @see {@link https://day.js.org/docs/en/plugin/localized-format} */
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { configure } from 'mobx';
import { GlobalLoading } from 'shared/lib/global-loading';
import { ErrorReqHandler } from 'shared/lib/unhandled-error';
import { observer } from 'mobx-react-lite';
import { analytics } from 'shared/lib/analytics';
import { MIXPANEL_KEY, MIXPANEL_DEBUG } from 'shared/config';
import { withProviders } from './providers';
import { UnauthorizedReqHandler, useInit } from './init';
import { DataInitialLoader } from './ui/data-initial-loader';

analytics.init({ token: MIXPANEL_KEY, debug: MIXPANEL_DEBUG });

dayjs.extend(localizedFormat);

/** @see {@link https://mobx.js.org/configuration.html#linting-options} */
configure({
  enforceActions: 'always',
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
});

const App = () => {
  const { inited, isAuth } = useInit();

  if (!inited) {
    return <DataInitialLoader />;
  }

  return (
    <div className="app">
      <UnauthorizedReqHandler />
      <ErrorReqHandler />
      <GlobalLoading />
      <Routing isAuth={isAuth} />
    </div>
  );
};

export default observer(withProviders(App));
