import mixpanel from 'mixpanel-browser';

type IdentifyParams = {
  id: string;
  email: string;
};
export const identify = (params: IdentifyParams | null) => {
  if (!params) {
    mixpanel.reset();

    return;
  }
  const { id, email } = params;

  mixpanel.identify(id);
  mixpanel.people.set({ $email: email, userId: id });
};
