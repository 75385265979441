import mixpanel from 'mixpanel-browser';

const event = <T>(name: string, params?: T) => {
  mixpanel.track(name, params);
};

export const visitedSignUpPage = () => {
  event('Visited Sign up page');
};

export const signedUpSuccessfully = (params: {
  enteredEmail: string;
  createdUserId: string;
}) => {
  event('Signed up successfully', params);
};

export const visitedSignInPage = () => {
  event('Visited Sign in page');
};

export const visitedForgotPasswordPage = () => {
  event('Visited Forgot password page');
};

export const visitedResetPasswordPage = () => {
  event('Visited Reset password page');
};

export const signedInSuccessfully = () => {
  event('Signed in successfully');
};

export const successfullyAddedApiKey = () => {
  event('Successfully added API key');
};
