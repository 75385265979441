import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
});

const designColors = {
  dark: '#0B293D',
  lightblue: '#C1E5F8',
  lightgrey: '#F8F9FA',
  darkgrey: '#6B767D',
  primarytext: '#2D3748',
  secondarytext: '#A0AEC0',
};

const secondary = {
  '50': '#f3fcff',
  '100': '#eefbff',
  '200': '#e3f7ff',
  '300': '#d7f2ff',
  '400': '#ccecfd',
  '500': designColors.lightblue,
  '600': '#b5d8ec',
  '700': '#a6c8db',
  '800': '#93b2c4',
  '900': '#7c95a4',
};

const primary = {
  '50': '#000000',
  '100': '#050606',
  '200': '#090c0d',
  '300': '#0c1619',
  '400': '#0e2028',
  '500': designColors.dark,
  '600': '#193e4f',
  '700': '#305c68',
  '800': '#528288',
  '900': '#83adae',
};

export const nmdTheme = extendTheme(
  {
    breakpoints,
    components: {
      Button: { baseStyle: { _focus: { boxShadow: 'none' } } }, // @see {@link https://github.com/chakra-ui/chakra-ui/issues/708#issuecomment-774478731}
      Link: {
        baseStyle: {
          _focus: { boxShadow: 'none' },
          _hover: { textDecoration: 'none' },
        },
      },
      Text: {
        baseStyle: { color: designColors.primarytext },
      },
    },
    fonts: {
      heading: 'Inter, Open Sans, sans-serif',
      body: 'Inter, Open Sans, sans-serif',
    },
    radii: {
      md: '10px',
    },
    colors: {
      ...designColors,
      secondary,
      primary,
    },
  },
  withDefaultColorScheme({ colorScheme: 'secondary' }),
  withDefaultColorScheme({ colorScheme: 'primary' }),
);
