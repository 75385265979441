import { AxiosResponse } from 'axios';
import { ApiResponse, apiInstance } from 'shared/lib/api';
import { ApiKey, ApiSecretKey, CreateApiKeyParams } from './types';

type GetApiKeysResponse = ApiResponse<ApiKey[]>;

export const getApiKeys = async (): Promise<
  AxiosResponse<GetApiKeysResponse>
> => {
  return apiInstance.get<GetApiKeysResponse>(
    '/dashboard-api/v1/current/api-keys',
  );
};

export type CreateApiKeyResponse = ApiResponse<ApiSecretKey>;

export const createApiKey = async (
  params: CreateApiKeyParams,
): Promise<AxiosResponse<CreateApiKeyResponse>> => {
  return apiInstance.post<CreateApiKeyResponse>(
    '/dashboard-api/v1/current/api-keys',
    params,
  );
};

export const deleteCreateApiKey = async (key: string): Promise<void> => {
  await apiInstance.delete(`/dashboard-api/v1/current/api-keys/${key}`);
};
