import { AxiosResponse } from 'axios';
import { ApiResponse, buildDevHeaders, apiInstance } from 'shared/lib/api';
import {
  AuthUser,
  PasswordResetParams,
  SignInParams,
  SignUpParams,
  VerifyEmailParams,
} from './types';

type SignInResponse = ApiResponse<AuthUser>;

/**
 * Auth user
 * @param params
 * @remark For this method to work, you need:
 * 1. PORT 8080
 * 2. HOST *.nearpay.co (e.g. development.nearpay.co)
 * @author Andrey Kornienko
 */
export const signIn = async (
  params: SignInParams,
): Promise<AxiosResponse<SignInResponse>> => {
  return apiInstance.post<SignInResponse>('/dashboard-api/v1/sign-in', params);
};

type SignUpResponse = ApiResponse<AuthUser>;

/**
 * Registration user
 * @param params
 * @remark For this method to work, you need:
 * 1. PORT 8080
 * 2. HOST *.nearpay.co (e.g. development.nearpay.co)
 * @author Andrey Kornienko
 */
export const signUp = async (
  params: SignUpParams,
): Promise<AxiosResponse<SignUpResponse>> => {
  return apiInstance.post<SignUpResponse>('/dashboard-api/v1/sign-up', params, {
    headers: buildDevHeaders({
      'X-WITH-CONFIRMATION': true,
      'X-WITH-EMAIL-VERIFICATION': false,
    }),
  });
};

export const signOut = async (): Promise<void> => {
  await apiInstance.post<SignInResponse>('/dashboard-api/v1/current/sign-out');
};

export const getCurrentUser = async (): Promise<
  AxiosResponse<ApiResponse<AuthUser>>
> => {
  return apiInstance.get<ApiResponse<AuthUser>>('/dashboard-api/v1/current');
};

export const resendEmailVerification = async (): Promise<void> => {
  await apiInstance.post('/dashboard-api/v1/current/verification/email');
};

export const verifyEmail = async (params: VerifyEmailParams): Promise<void> => {
  const { id, hash, expires, signature } = params;
  await apiInstance.post(
    `/dashboard-api/v1/verification/email/${id}/${hash}?expires=${expires}&signature=${signature}`,
  );
};

export const sendPasswordForgotEmail = async (email: string): Promise<void> => {
  await apiInstance.post('/dashboard-api/v1/password-forgot', {
    email,
  });
};

export const changePassword = async (
  params: PasswordResetParams,
): Promise<void> => {
  await apiInstance.post('/dashboard-api/v1/password-reset', params);
};
