import { AxiosResponse } from 'axios';
import { apiInstance } from 'shared/lib/api';
import {
  GetTransactionMetricsParams,
  GetTransactionMetricsResponse,
} from './types';

export const getTransactionMetrics = async (
  params?: GetTransactionMetricsParams,
): Promise<AxiosResponse<GetTransactionMetricsResponse>> => {
  return apiInstance.get<GetTransactionMetricsResponse>(
    '/dashboard-api/v1/current/transaction-metrics',
    { params },
  );
};
