import { authUserModel } from 'entities/auth-user';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { apiInstance } from 'shared/lib/api/';

export const UnauthorizedReqHandler = observer(() => {
  const { setUser } = authUserModel.store;
  const navigate = useNavigate();

  useEffect(() => {
    const interceptor = apiInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          setUser(null);
          navigate('/sign-in');

          return Promise.resolve();
        }

        return Promise.reject(error);
      },
    );
    return () => apiInstance.interceptors.response.eject(interceptor);
  }, [setUser, navigate]);

  return null;
});
