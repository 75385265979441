import { AxiosResponse } from 'axios';
import { apiInstance } from 'shared/lib/api';
import { GetSettingsResponse, Settings, UpdateSettingsResponse } from './types';

export const getSettings = async (): Promise<
  AxiosResponse<GetSettingsResponse>
> => {
  return apiInstance.get<GetSettingsResponse>(
    '/dashboard-api/v1/current/settings',
  );
};

export const updateSettings = async (
  params: Settings,
): Promise<AxiosResponse<UpdateSettingsResponse>> => {
  return apiInstance.post<UpdateSettingsResponse>(
    '/dashboard-api/v1/current/settings',
    params,
  );
};
