const unhandledErrorEvent = new Event('unhandled-error');

export const callUnhandledError = () =>
  document.dispatchEvent(unhandledErrorEvent);

export const unhandledErrorWrapper = <P, R>(
  cb: (p: P) => Promise<R>,
): ((p: P) => Promise<R>) => {
  return async (p) => {
    try {
      return await cb(p);
    } catch (error) {
      callUnhandledError();
      throw error;
    }
  };
};
