import { makeAutoObservable } from 'mobx';

class GlobalLoadingStore {
  private _runningCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get isRunning(): boolean {
    return this._runningCount > 0;
  }

  run(): GlobalLoadingStore {
    this._runningCount += 1;

    return this;
  }

  stop(): GlobalLoadingStore {
    if (this._runningCount === 0) {
      throw new Error('runningCount is 0');
    }

    this._runningCount -= 1;

    return this;
  }
}

export const globalLoadingStore = new GlobalLoadingStore();

export const globalLoading = async <T>(cb: () => T): Promise<T> => {
  try {
    globalLoadingStore.run();
    return await cb();
  } finally {
    globalLoadingStore.stop();
  }
};
