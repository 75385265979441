import { autorun } from 'mobx';
import { analytics } from 'shared/lib/analytics';
import { store } from '../model';

export const runAnalytics = () => {
  autorun(() => {
    if (store.request.data) {
      const { id, email } = store.request.data.data.result;
      analytics.identify({ id, email });
    }
  });
};
