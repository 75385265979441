// creates new async function, which will join multiple `awaits` to a single promise instance
export function singleInFlight<T, Args extends unknown[]>(
  asyncFunction: (...params: Args) => Promise<T>,
): (...params: Args) => Promise<T> {
  let activePromise: Promise<T> | null = null;

  return (...params) => {
    if (!activePromise) {
      const current = asyncFunction(...params);
      activePromise = current;
      // clear promise after everything
      current.then(() => {
        activePromise = null;
      });

      return activePromise;
    }

    return activePromise;
  };
}
