import type { AxiosResponse } from 'axios';
import { apiInstance, ApiResponse } from 'shared/lib/api';
import type {
  GetReferralOperationsApiResponse,
  GetReferralOperationsParams,
  SettlementCurrency,
  ReferralCode,
  WithdrawParams,
} from './types';

export const getReferralOperations = async (
  params: GetReferralOperationsParams,
): Promise<AxiosResponse<GetReferralOperationsApiResponse>> => {
  const filter = Object.entries<string>(params.filters).reduce(
    (acc, item) => ({ ...acc, [`filters[${item[0]}]`]: item[1] }),
    {},
  );

  return apiInstance.get<GetReferralOperationsApiResponse>(
    '/dashboard-api/v1/current/referral-program/operations',
    {
      params: {
        page: params.page,
        ...filter,
      },
    },
  );
};

export const getSettlementCurrencies = async (): Promise<
  AxiosResponse<ApiResponse<SettlementCurrency[]>>
> => {
  return apiInstance.get<ApiResponse<SettlementCurrency[]>>(
    '/dashboard-api/v1/current/referral-program/settlement-currencies',
  );
};

export const getCurrencies = async (): Promise<
  AxiosResponse<ApiResponse<string[]>>
> => {
  return apiInstance.get<ApiResponse<string[]>>(
    '/dashboard-api/v1/current/referral-program/currencies',
  );
};

export const getReferralCodes = async (): Promise<
  AxiosResponse<ApiResponse<ReferralCode[]>>
> => {
  return apiInstance.get<ApiResponse<ReferralCode[]>>(
    '/dashboard-api/v1/current/referral-program/referral-codes',
  );
};

export const withdraw = async (
  params: WithdrawParams,
): Promise<AxiosResponse<void>> => {
  return apiInstance.post<void>(
    '/dashboard-api/v1/current/referral-program/settlement',
    params,
  );
};
