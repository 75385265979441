import { authUserModel } from 'entities/auth-user';
import { useEffect, useState } from 'react';

export const useInit = () => {
  const { isAuth, load } = authUserModel.store;
  const [inited, setInited] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        await load();
      } finally {
        setInited(true);
      }
    };
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isAuth,
    inited,
  };
};
