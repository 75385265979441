type DevHeader = 'X-WITH-EMAIL-VERIFICATION' | 'X-WITH-CONFIRMATION';
export const buildDevHeaders = (headers: { [name in DevHeader]?: boolean }) => {
  const head: { [name in DevHeader]?: '' } = {};

  (Object.keys(headers) as Array<DevHeader>).forEach((name: DevHeader) => {
    if (headers[name] === true) {
      head[name] = '';
    }
  });

  return head;
};
