import './styles.scss';
import { block } from 'bem-cn';
import { Center, Spinner } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { globalLoadingStore } from '../global-loading';

const b = block('nmd-dimmer');

const LoadingLocal: React.FC = () => {
  if (!globalLoadingStore.isRunning) {
    return null;
  }

  return (
    <div className={b()}>
      <Center mt="250px">
        <Spinner thickness="4px" speed="0.65s" color="lightblue" size="xl" />
      </Center>
    </div>
  );
};

export const GlobalLoading = observer(LoadingLocal);
